import React from 'react';

function History(props) {
  const { title, blurb } = props.content;
  return (
    <section id="history" className="home-section">
      <h2>{title}</h2>
      <p className="blurb">{blurb}</p>
    </section>
  );
}

export default History;