import React from 'react';

function Contact(props) {

  const ContactForm = () => (
    <form className="contact-form" name="contact" method="post" data-netlify-honeypot="bot-field" data-netlify-recaptcha="true" data-netlify="true">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className="input-container">
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" />
      </div>
      <div className="input-container">
        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" />
      </div>
      <div className="input-container">
        <label htmlFor="message">Message</label>
        <textarea id="message" name="message"></textarea>
      </div>
      <div class="g-recaptcha" data-sitekey="6LeNOtYUAAAAAK35CyZQxX_cwpYCoJhn3IIfelky"></div>
      <div className="input-container">
        <input type="submit" value="Send Message" className="button" />
      </div>
    </form>
  );

  const { title, blurb } = props.content;
  return (
    <section id="contact" className="home-section">
      <h2>{title}</h2>
      <p className="blurb">{blurb}</p>
      <ContactForm />

    </section>
  );
}

export default Contact;