import React from 'react'
import Helmet from 'react-helmet'
import HeadingImage from '../HeadingImage'
import HomeNav from '../HomeNav'
import About from '../About'
import History from '../History'
import Donate from '../Donate'
import ThisYear from '../ThisYear'
import Media from '../Media'
import SisterConcerts from '../SisterConcerts'
import Contact from '../Contact'
import Links from '../Links'

import SnowVideo from '../../assets/img/snow.webm';
import SnowPicture from '../../assets/img/Snow.jpg';
import Divider from '../Divider'

const HomePageTemplate = ({
  meta_title,
  meta_description,
  about,
  history,
  donations,
  this_year,
  media,
  sister_concerts,
  contact,
  links
}) => {
  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
        <meta name='description' content={meta_description} />
      </Helmet>
      <main className="home">
        <div className="home__nav">
          <HomeNav />
        </div>
        <div className='home__content'>
          <div className="content-container">
            <HeadingImage />
            <About content={about} />
            <Divider />
            <History content={history} />
            <Divider />
            <Donate content={donations} />
            <Divider />
            <ThisYear content={this_year} />
            <Divider />
            <Media content={media} />
            <Divider />
            <SisterConcerts content={sister_concerts} />
            <Divider />
            <Contact content={contact} />
            <Divider />
            <Links content={links} />
          </div>
        </div>
      </main>
      <div className="blue-background background"></div>
      <video autoPlay loop className="background" id="video-background" poster={SnowPicture} muted>
        <source src={SnowVideo} type="video/webm" />
      </video>
    </div>
  )
}

// TODO: Make propTypes
// HomePageTemplate.propTypes = {
//   title: PropTypes.string,
//   meta_title: PropTypes.string,
//   meta_description: PropTypes.string,
//   heading: PropTypes.string,
//   description: PropTypes.string,
//   offerings: PropTypes.shape({
//     blurbs: PropTypes.array,
//   }),
//   testimonials: PropTypes.array,

// }

export default HomePageTemplate
