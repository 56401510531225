import React from 'react';

const ScheduleItem = (props) => {
  const { event, time } = props.scheduleItem;
  return (
    <tr className="schedule__schedule-item">
      <td className="schedule-item__time">{time}</td>
      <td className="schedule-item__event">{event}</td>
    </tr>
  );
}

function Schedule(props) {
  const { schedule } = props;
  return (
    <section className="schedule">
      <table>
        <tbody>
        {
          schedule.map(s =>
            <ScheduleItem key={s.time} scheduleItem={s} />
          )
        }
        </tbody>
      </table>
    </section>
  );
}


export default Schedule;