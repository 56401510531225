import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HomePageTemplate from '../components/HomePageTemplate'
import Layout from '../components/Layout'

const HomePage = ({ data }) => {
  const { title, meta_title, meta_description, about, history, donations, this_year, sister_concerts, media, contact, links, secret_window } = data.markdownRemark.frontmatter

  return (
    <Layout secret_window={secret_window}>
      <HomePageTemplate
        title={title}
        meta_title={meta_title}
        meta_description={meta_description}
        about={about}
        history={history}
        donations={donations}
        this_year={this_year}
        sister_concerts={sister_concerts}
        media={media}
        contact={contact}
        links={links}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        about {
          title
          blurb
        }
        history {
          title
          blurb
        }
        donations {
          title
          blurb
          donate_button_text
          donate_button_destination
          donate_button_tooltip
        }
        this_year {
          title
          date
          time
          location
          address {
            street
            city
            state
            zip
          }
          schedule {
            event
            time
          }
        }
        media {
          title
          main_video
        }
        sister_concerts {
          title
          blurb
        }
        contact {
          title
          blurb
        }
        links {
          title
          links {
            link
            link_destination
          }
        }
        secret_window {
          secret_code
          title
          blurb
        }
      }
    }
  }
`
