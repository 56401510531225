import React from 'react';
import logo from '../../assets/img/logo-s.png';

const HeadingImage = () => {
  return (
    <div className="heading-image__container">
      <img className="heading-image__image" src={logo} alt="Winter Songs" />
      <div className="heading-image__spacer"></div>
    </div>
  )
}

export default HeadingImage
