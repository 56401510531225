import React from 'react';

function Media(props) {
  const { title, main_video } = props.content;
  return (
    <section id="media">
      <h2>{title}</h2>
      <div className="video">
        <div className='embed-container'>
          <iframe src={main_video} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </section>
  );
}

export default Media;