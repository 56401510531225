import React from 'react';
import Schedule from '../Schedule';

function ThisYear(props) {
  const { title, date, time, location, address, schedule } = props.content;
  const { street, city, state, zip } = address;
  const dateObj = new Date(date);
  return (
    <section id="this-year">
      <h2>{title}</h2>
      <div className="this-year__container">
        <div className="detail-container">
          <div className="this-year__date-time this-year__details">
            <p>{time}</p>
            <time dateTime={dateObj}>
              {dateObj.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            </time>
          </div>
          <div className="this-year__details">
            <p className="location">{location}</p>
            <p className="this-year__address">
              {street}<br />
              {city}, {state} {zip}
            </p>
          </div>
        </div>
        <Schedule schedule={schedule} />
      </div>
    </section>
  );
}

export default ThisYear;