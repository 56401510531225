import React from 'react';

function Links(props) {
  const { title, links } = props.content;
  return (
    <section id="links">
      <h2>{title}</h2>
      <ul>
        {
          links.map(l => (
            <li key={l.link}>
              <a href={l.link_destination}>{l.link}</a>
            </li>
          ))
        }
      </ul>
    </section>
  );
}

export default Links;