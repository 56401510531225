import React from 'react';

function Donate(props) {
  const { title, blurb, donate_button_text, donate_button_destination,donate_button_tooltip } = props.content;
  return (
    <section id="donate" className="home-section">
      <h2>{title}</h2>
      <div>
        <p className="blurb">{blurb}</p>
        <a className="link-button" title={donate_button_tooltip} href={donate_button_destination}>{donate_button_text}</a>
      </div>
    </section>
  );
}

export default Donate;