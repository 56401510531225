import React from 'react';

import InternalLink from '../InternalLink';

function HomeNav(props) {


  return (
    <nav className="home-nav">
      <ul>
        <li><InternalLink to="#about">About</InternalLink></li>
        <li><InternalLink to="#history">History</InternalLink></li>
        <li><InternalLink to="#donate">Donations</InternalLink></li>
        <li><InternalLink to="#this-year">This Year</InternalLink></li>
        <li><InternalLink to="#media">Media</InternalLink></li>
        <li><InternalLink to="#sister_concerts">Sister Concerts</InternalLink></li>
        <li><InternalLink to="#contact">Contact</InternalLink></li>
        <li><InternalLink to="#links">Links</InternalLink></li>
      </ul>
    </nav>
  );
}

export default HomeNav;