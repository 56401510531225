import React from 'react';

function SisterConcerts(props) {
  const { title, blurb } = props.content;
  return (
    <section id="sister_concerts" className="home-section">
      <h2>{title}</h2>
      <p className="blurb">{blurb}</p>
    </section>
  );
}

export default SisterConcerts;